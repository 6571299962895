<template>
	<div>
		<div class="login">
			<div class="contain">
				<div class="right">
					<!-- 切换 -->
					<!-- <div class="mode mode1" @click="yzmLOGIN" v-if="subfrom.type == '2'">
						<div class="modeText">
							切换个人注册
						</div>
					</div>
					<div class="mode mode1" @click="smLOGIN" v-if="subfrom.type == '1'">
						<div class="modeText">
							切换企业注册
						</div>
					</div> -->
					<div>
						<div class="textIn">
							-
							+
							<div class="line">
								<i class="el-icon-user"></i>
								<input type="text" v-model="subfrom.mobile" placeholder="用户名" />
							</div>
							<div v-if="subfrom.type == 1">
							</div>
							<!-- 短信 -->
							<div v-if="subfrom.type == 2">
								<div class="line">
									<i class="el-icon-user"></i>
									<input type="text" v-model="subfrom.name" placeholder="企业名称" />
								</div>
							</div>
							<div class="line">
								<i class="el-icon-unlock"></i>
								<input :type='pwdFlag?"password":"text"' v-model="subfrom.password" placeholder="密码"
									@input="pwStrength($event.target.value)" />
							</div>
							<div class="tip">
								密码强度：
								<span id="strength_L">危险</span>
								<span id="strength_M">一般</span>
								<span id="strength_H">安全</span>
								<a @click="btnPass()">{{pwdFlag?"显示密码":"隐藏密码"}}
									<i class="el-icon-info"></i></a>
							</div>
							<div class="tip">
								密码必须包含数字、字母、符号
							</div>
							<div class="line">
								<i class="el-icon-unlock"></i>
								<input type="password" v-model="subfrom.repassword" placeholder="确认密码" />
							</div>
							<div class="line">
								<i class="el-icon-unlock"></i>
								<input type="text" v-model="subfrom.code" placeholder="请输入验证码" />
								<span id="canvasCode" class="code"></span>
							</div>
							<div class="btn" @click="Registerusers">{{ subfrom.type == 1 ? "个人" : "企业" }}立即注册</div>
						</div>
					</div>
					<el-checkbox v-model="checked">我已阅读并同意<b><a @click="goto('/info?id=12923')">《临海人才网用户服务协议》</a></b>
					</el-checkbox>
					<div class="tips">
					</div>
				</div>
				<div class="left">
					<div class="logo"><img src="../assets/img/logo.png" alt="" /></div>
					<img src="../assets/img/pic.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		CompanyLogin,
		UserLogin,
		Registerwx,
		Wxbinduser,
		GetNewsByType,
	} from "@/api/user";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		getGVerify
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	export default {
		data() {
			return {
				interval: null,
				buttonName: "发送验证码",
				isDisabled: false,
				time: 60,
				activeName: "first",
				sm: false,
				yzm: true,
				checked: false,
				pwdFlag: true,
				subfrom: {
					mobile: "",
					name: "",
					password: "",
					repassword: "",
					unionid: "",
					type: 1, // 1 个人 2 企业
				},
				GVerifyCode: null,
			};
		},
		created() {
			var type = this.$route.query.type;
			if (!isnull(type)) {
				this.subfrom.type = parseInt(type);
			}
			if (!isnull(localStorage.getItem("wxtoken"))) {
				this.subfrom.unionid = localStorage.getItem("wxtoken");
			}
			this.GetNewsByType();
		},
		mounted() {
			this.getGVerify();
		},
		methods: {
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "100",
					height: "40",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			btnPass() {
				this.pwdFlag = this.pwdFlag ? false : true;
				this.subfrom.password=this.subfrom.password;
			},
			pwStrength(e) {
				utils.pwStrength(e)
			},
			handleClick() {
				this.isDisabled = false;
				this.subfrom.mobile = "";
				this.subfrom.name = "";
				this.subfrom.password = "";
				this.subfrom.repassword = "";
			},
			GetNewsByType() {
				var _this = this;
				GetNewsByType().then((res) => {
					if (res.success) {} else {
						_this.$message.error(res.msg);
					}
				});
			},
			smLOGIN: function() {
				this.subfrom.type = 2;
			},
			yzmLOGIN: function() {
				this.subfrom.type = 1;
			},
			goto(url) {
				this.$router.push(url);
			},
			//注册
			Registerusers() {
				var msg = "";
				var _this = this;
				/*var cphone = utils.telVerify(_this.subfrom.mobile);
				if (!cphone.state) {
					msg = cphone.message;
				} else */
				if (isnull(_this.subfrom.mobile)) {
					msg = "请输入用户名";
				} else if (isnull(_this.subfrom.password)) {
					msg = "请输入密码";
				} else if (isnull(_this.subfrom.repassword)) {
					msg = "请输入确认密码";
				} else if (_this.subfrom.repassword != _this.subfrom.password) {
					msg = "两次密码不一致";
				} else if (!_this.checked) {
					msg = "请先确认用户协议";
				}

				if (_this.subfrom.type === "2") {
					if (isnull(_this.subfrom.name)) {
						msg = "请输入企业名称";
					}
				}
				var flag = this.GVerifyCode.validate(_this.subfrom.code);
				if (!flag) {
					msg = "验证码错误";
					this.GVerifyCode.refresh();
				}
				if (!isnull(msg)) {
					_this.$message({
						message: msg,
						type: "warning",
					});
					return false;
				}

				var rdata = new Array();
				rdata = Registerwx(_this.subfrom);
				/*if (_this.activeName === "first") {
				} else {
					var par = {
						name: _this.subfrom.name,
						mobile: _this.subfrom.mobile,
						password: _this.subfrom.password,
						unionid: _this.subfrom.unionid,
						type: _this.subfrom.type, // 1 个人 2 企业
					};
					rdata = Wxbinduser(par);
				}*/

				rdata.then((res) => {
					if (res.success) {
						var parr = {
							mobile: "",
							name: "",
							password: "",
							type: _this.subfrom.type,
						};
						if (_this.activeName == "first") {
							parr = {
								mobile: _this.subfrom.mobile,
								password: _this.subfrom.password,
								type: _this.subfrom.type,
							};
						} else {
							parr.mobile =
								_this.subfrom.type === 1 ?
								res.data.peR_ACCOUNT :
								res.data.coM_ACCOUNT;
							parr.password =
								_this.subfrom.type === 1 ? res.data.peR_PWD : res.data.coM_PWD;
						}
						_this.logininfo(parr);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			logininfo(ld) {
				var _this = this;
				var rdatas = new Array();
				if (_this.subfrom.type === 1) {
					rdatas = UserLogin(ld);
				} else {
					rdatas = CompanyLogin(ld);
				}
				rdatas.then((res) => {
					if (res.success) {
						this.$message({
							message: "登录成功",
							type: "success",
						});
						var rdata = res.data;
						rdata.user.userType = _this.subfrom.type;
						localStorage.setItem("token", rdata.token);
						localStorage.setItem("userinfo", JSON.stringify(rdata.user));
						setTimeout(_this.resolvewin("/", {}, 1), 500);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	// 复选框
	.el-checkbox:last-of-type {
		margin-top: 10px;
	}

	/deep/.el-checkbox:last-of-type .el-checkbox__label {
		font-size: 12px;
		color: #999;

		b {
			font-weight: normal;
			color: #0096FF;
		}
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #999;

		b {
			font-weight: normal;
			color: #0096FF;
		}
	}

	/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #dcdfe6;
	}

	// tab
	/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		border-radius: 0;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border: none;
	}

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin: 0;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
		width: 48%;
		height: 45px;
		text-align: center;
		box-sizing: border-box;
		border-bottom: 1px solid #ececec;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		color: #276bf2;
		border-bottom: 1px solid #276bf2;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
		color: #276bf2;
	}

	// 内容
	.login {
		width: 100%;
		height: 100vh;
		background: url(../assets/img/login.png) bottom center;
		display: flex;
		align-items: center;
		justify-content: center;

		.contain {
			width: 800px;
			height: 605px;
			display: flex;
			background: white;
			border-radius: 4px;
			overflow: hidden;

			.left {
				padding-top: 140px;
				box-sizing: border-box;
				width: 280px;
				height: 100%;
				background: #f2f7fc;
				text-align: center;

				img {
					width: 236px;
				}

				.logo {
					margin-bottom: 60px;

					img {
						width: 230px;
					}
				}
			}

			.right {
				width: 480px;
				padding: 80px 80px 0;
				box-sizing: border-box;
				position: relative;

				.scanning {
					font-size: 14px;
					color: #999;
					text-align: center;

					img {
						width: 215px;
						margin-top: 40px;
					}

					b {
						color: #276bf2;
					}

					.title {
						font-size: 20px;
						color: #333;
						margin-bottom: 40px;
					}
				}

				.mode1 {
					left: 25px;
				}

				.mode {
					position: absolute;
					top: 25px;
					right: 25px;
					display: flex;
					align-items: center;

					.modeText {
						width: 200px;
						height: 40px;
						background: #538fff;
						font-size: 12px;
						color: white;
						line-height: 40px;
						text-align: center;
						margin-right: 15px;
						position: relative;
					}

					.modeText:after {
						position: absolute;
						//content: "";
						left: 200px;
						top: 10px;
						border-top: 10px solid transparent;
						border-left: 10px solid #538fff;
						border-bottom: 10px solid transparent;
					}

					img {
						width: 25px;
					}
				}

				.mode:hover {
					cursor: pointer;
				}

				.textIn {
					width: 100%;

					.line {
						width: 100%;
						height: 40px;
						border: 1px solid #ececec;
						box-sizing: border-box;
						margin-top: 24px;
						line-height: 40px;
						padding: 0 15px;
						color: #999;
						display: flex;
						align-items: center;
						position: relative;
						background-color: #F5F5F5;
						border-radius: 8px;
						overflow: hidden;

						.tel {
							font-size: 14px;
							color: #333;
							margin-left: 15px;
						}

						.el-icon-arrow-down {
							font-size: 14px;
							color: #333;
							margin-left: 5px;
						}

						input {
							width: 80%;
							margin-left: 10px;
							font-size: 14px;
							background-color: #F5F5F5;
						}

						.code {
							height: 40px;
							position: absolute;
							right: 0;
						}

						.send {
							font-size: 14px;
							color: #276bf2;
							position: absolute;
							right: 10px;
							cursor: pointer;
						}
					}

					.smsbtn {
						float: right;
						border-radius: 8px;
						font-size: 14px;
						height: 40px;
						line-height: 40px;
						color: #0096FF;
						width: 95px;
						margin-top: 25px;
						background: #DDF1FF;
						text-align: center
					}

					.tip {
						font-size: 12px;
						color: #898989;
						margin-top: 17px;

						span {
							margin: 0 5px;
							background-color: #eee;
							color: #010101;
							width: 56px;
							line-height: 20px;
							text-align: center;
							display: inline-block;
						}

						a {
							color: #0096FF;
							position: relative;

							i {
								position: absolute;
								right: -20px;
								top: 3px;
							}
						}
					}

					.btn {
						width: 100%;
						height: 40px;
						border-radius: 8px;
						line-height: 40px;
						text-align: center;
						font-size: 14px;
						color: white;
						background: #0096FF;
						margin-top: 25px;
						cursor: pointer;
					}

					.free {
						color: #999;
						font-size: 14px;
						margin-top: 20px;
						text-align: right;
					}

					.free:hover {
						cursor: pointer;
					}
				}

				.tips {
					width: 100%;
					font-size: 12px;
					color: #999;
					text-align: center;
					line-height: 2;
					margin-top: 20px;

					b {
						font-weight: normal;
						color: #0096FF;
					}
				}
			}
		}
	}
</style>
